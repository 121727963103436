/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600;700&display=swap'); */
:root {
  --globalBlue: linear-gradient(15deg, rgb(20, 52, 133), rgb(20, 95, 133));
  --textColor: #222;
  --lightColor: #666;
  --whiteColor: #fff;
  --blueColor: #143485;
}

body {
  color: var(--textColor);
  font-family: 'Poppins', sans-serif;
  transition: 0.5s;
}

* {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: var(--textColor);
}
.btn-global {
  background: var(--globalBlue);
}
.btn-global:hover {
  color: #fff !important;
}

.cursor-pointer {
  cursor: pointer;
}
.underline-text {
  text-decoration: underline;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
} 

/**************Global CSS*****************/

body.modal-open {
  padding-right: 0 !important;
}
.text-justify {
  text-align: justify;
}
.otpPopup-Wrapper .modal-dialog {
  height: 90%;
  align-items: center;
  display: flex;
  max-width: 700px;
}
.otpPopup-Wrapper .modal-dialog .modal-content {
  border-radius: 0;
}
.otpPopup-Wrapper .modal-dialog .modal-content .modal-header {
  display: block;
  background: #222c83;
  border-radius: 0;
  border: none;
  text-align: center;
}
.otpPopup-Wrapper .modal-dialog .modal-content .modal-header h4 {
  font-size: 30px;
  color: #fff;
}
.otpPopup-Wrapper .modal-dialog .modal-content .modal-header .btn-close {
  position: absolute;
  right: 30px;
  top: 26px;
  color: #fff;
  background: none;
  padding: 0;
  opacity: 1;
}
.otpPopup-Wrapper .modal-dialog .modal-content .modal-body .popupHeader h3 {
  font-size: 24px;
  text-align: center;
  padding: 0 70px;
}
.otpPopup-Wrapper .modal-dialog .modal-content .modal-body .popupHeader p {
  text-align: center;
}
.otpPopup-Wrapper .modal-dialog .modal-content .modal-footer {
  display: block;
  text-align: center;
  border: none;
  padding: 20px 0 30px;
}
.otpPopup-Wrapper .modal-dialog .modal-content .modal-footer .btn {
  width: 175px;
  height: 52px;
  border-radius: 30px;
  margin: 0 0 12px;
}
.otpPopup-Wrapper .modal-title.h4 {
  color: #fff;
}
.otpPopup-Wrapper .modal-dialog .modal-content .modal-body {
  padding: 40px 40px 20px;
}
.otpInput {
  max-width: 400px;
  margin: 40px auto 0;
}

.line-height-1 {
  line-height: 1;
}


.bg-body-tertiary {
  background-image: var(--globalBlue);
}

a {
  position: relative;
  color: inherit;
  text-decoration: none;
  line-height: 1.5rem;
}
a:before, a:after {
  content: '';
  position: absolute;
  transition: transform .5s ease;
}
.nav-link {
  padding-top: 0.625rem;
}

.offcanvas.offcanvas-end {
  border-left: none !important;
}

.offcanvas-header .btn-close {
  border: 2px dotted #222;
  opacity: 1;
  color: #fff;
  margin-right: 0 !important;
}

.form-check-input:checked[type=radio] {
  background: #143e85;
  border: 4px solid #fff;
  position: relative;
  box-shadow: none;
}
.form-check-input:checked[type=radio]::after {
  content: '';
  position: absolute;
  border: 1px solid #ddd;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: -4px;
  top: -4px;
}
.text-blue {
  color: rgb(23 42 91) !important;
}
.lead {
  font-size: 16px; 
}

.form-control:focus { 
  border-color: #333 !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.imageUploadBtn {
  min-width: 100px; 
}
.imageUploadPopup .modal-body svg {
  left: auto !important; right: 20px;
}
.btn-close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.btn-close:hover {
  opacity: 1;
}
.btn-close:before, .btn-close:after {
  position: absolute;
  left: 2px;
  content: ' ';
  height: 22px;
  width: 2px;
  background-color: #fff;
  top: 3px;
}
.btn-close:before {
  transform: rotate(45deg);
}
.btn-close:after {
  transform: rotate(-45deg);
}


nav.sticky{
  position: fixed;
  z-index: 100; 
  top: 0;
  width: 100%;
  -webkit-animation: smoothScroll 1s forwards;
          animation: smoothScroll 1s forwards; 
}
@-webkit-keyframes smoothScroll {
  0% {
      -webkit-transform: translateY(-40px);
              transform: translateY(-40px);
  }
  100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
  }
}
@keyframes smoothScroll {
  0% {
      -webkit-transform: translateY(-40px);
              transform: translateY(-40px);
  }
  100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .container, .container-sm {
    max-width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 100% !important;
    padding: 0 2rem !important;
  }
}