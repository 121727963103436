.profileWrapper { 
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
}
.profileDetails .basicInfo, .profileCaption {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1.375rem;
    box-shadow: 0px 3px 10px #ddd;
}
.profileDetails .basicInfo .infoRow {
    margin-bottom: 10px;
}
.profileDetails .basicInfo .infoRow label {
    font-weight: 500;
    margin-right: 20px;
    width: 135px;
    display: inline-block;
}
.profileDetails .basicInfo .infoRow span { 
    width: calc(100% - 160px);
    display: inline-block;
    vertical-align: top;
    word-break: break-all;
}
.profileDetails .skills {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1.375rem;
    box-shadow: 0px 3px 10px #ddd;
}
.profileDetails .skills label {
    font-weight: 500;
    margin-bottom: 20px;
}
.profileCaption .profilePic {
    box-shadow: 0px 7px 10px #ddd;
    border-radius: 50%;
    width: 150px;
    height: 150px;
}
.nav-link.btn-global {
    background: var(--globalBlue);
    width: 130px;
    padding: 10px;
    border-radius: 30px;
    color: #fff !important;
    margin: auto;
}
.nav-link.btn-global::after,
.nav-link.btn-global::before {
    display: none;
}
.profileCaption .followIcons .nav-link {
    border: 1px solid #144F85;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    margin-right: 16px;
    padding: 7px;
}
.profileCaption .followIcons .nav-link::after,
.profileCaption .followIcons .nav-link::before {
    display: none;
}
.profileCaption .followIcons .nav-link * {
    color: #144F85;
}

@media (min-width: 1025px) and (max-width: 1200px) {
    .profileCaption .followIcons .nav-link { 
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-right: 6px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .profileWrapper {
        padding-top: 20px;
    }
    .profileDetails .basicInfo, .profileCaption, .profileDetails .skills {
        padding: 1rem;
    }
    .profileCaption .followIcons .nav-link { 
        border-radius: 50%;
        width: 35px;
        height: 35px; 
        margin-right: 4px; 
    }
    .profileCaption .profilePic { 
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 767px) {
    .profileWrapper {
        padding-top: 20px;
    }
    .profileDetails .basicInfo, .profileCaption, .profileDetails .skills {
        padding: 1rem;
    }
    .profileCaption .followIcons .nav-link { 
        border-radius: 50%;
        width: 35px;
        height: 35px; 
        margin-right: 10px; 
    }
}