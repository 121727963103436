.expertWrapper {
    padding-top: 72px; 
}
.expertWrapper .mentor_Wrapper {
    padding: 0;
    background: none;
}
.expertWrapper .mentor_Wrapper .mentorSection {
    width: 100%; 
    margin: 0 0 120px; 
    padding: 110px 30px 40px;
}
.expertWrapper .col-md-4:last-child .mentor_Wrapper .mentorSection {
    margin-bottom: 0;
}

@media (min-width: 991px) {
    .expertWrapper .col-md-4:nth-last-child(1) .mentor_Wrapper .mentorSection {
        margin-bottom: 0;
    }
    .expertWrapper .col-md-4:nth-last-child(2) .mentor_Wrapper .mentorSection {
        margin-bottom: 0;
    }
    .expertWrapper .col-md-4:nth-last-child(3) .mentor_Wrapper .mentorSection {
        margin-bottom: 0;
    }
}
