/* .searchTopWrap {
    background-image: linear-gradient(180deg, rgba(20, 81, 135, 1), rgba(20, 81, 135, 0));
    min-height: 220px;
} */
.jobListWrapper {
    background: #f3f3f3;
} 
.jobCard {
    padding: 6px;
    border: 1px solid #ddd;
    width: 300px;  
    border-radius: 10px;
    background: #fff;
}
.jobCard .innerJobCard {
    background: #FFE1CB; 
    padding: 14px 1rem;
    border-radius: 10px;
    position: relative;
    min-height: 225px;
}
.jobCard .innerJobCard .companyLogo {
    position: absolute;
    right: 15px;
    top: 66px;
}
.jobCard .innerJobCard .jobDate {
    background: #fff;
    border-radius: 20px;
    padding: 6px 8px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 1rem;
}
.jobCard .innerJobCard .companyName {
    font-size: 1rem;
    margin-bottom: 8px;
    word-wrap: break-word;
    padding-right: 50px;
    max-height: 40px;
    line-height: 1.25;
}
.jobCard .innerJobCard .profileName {
    font-size: 20px;
    font-weight: 500;
    padding-right: 50px;
    line-height: 1.25;
    margin-bottom: 12px;
}
.jobCard .innerJobCard ul li {
    display: inline-block;
    border: 1px solid #666;
    border-radius: 20px;
    padding: 6px 8px;
    font-size: 12px;
    margin-right: 6px;
    margin-bottom: 6px;
}
.jobPriceLocationWrap {
    padding: 1rem 0;
}
.jobPriceLocationWrap .jobPriceLocation .jobLocation {
    color: #666;
    line-height: 1;
    font-size: 14px;
}
.jobPriceLocationWrap .jobPriceLocation .jobPrice {
    font-weight: bold;
}
.jobPriceLocationWrap .btn {
    background: var(--globalBlue);
    width: 110px;
    height: 34px;
    border-radius: 30px;
    color: #fff;
    font-size: 12px;
    text-decoration: none;
    line-height: 34px;
}
.jobPriceLocationWrap .btn:hover {
    color: #fff;
}
.jobPriceLocationWrap .btn::after,
.jobPriceLocationWrap .btn::before {
    display: none;
}

@media (min-width: 1201px) and (max-width: 1400px) {
    .jobCard { 
        width: 260px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .searchTopWrap {
        min-height: 150px;
    }
    .jobCard .innerJobCard .profileName {
        font-size: 18px; 
        padding-right: 40px;
    }
    .jobCard .innerJobCard .companyLogo img {
        width: 40px;
    }
    .jobPriceLocationWrap .btn {
        width: 70px;
    }
}

@media (max-width: 767px) {
    .jobCard {
        width: 100%;
    }
    .jobCard .innerJobCard .companyLogo {
        top: 45px;
    }
    .recommended_Job_row {
        padding: 2rem 0 1rem;
    }
    .recomJob {
        font-size: 14px;
    }
    .sortBy {
        font-size: 12px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .jobCard {
        width: 48%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .jobCard {
        width: 48%;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .jobCard {
        width: 45%;
    }
}