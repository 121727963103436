.footer {
    background-image: var(--globalBlue);
}
.footer .container {
    padding: 0 1rem;
}
/* .footer [class^="col-"] *, [class*=" col-"] * {
    color: var(--whiteColor);
} */
.footer .nav .nav-link {
    font-weight: 300;
    font-size: 0.875rem;
    padding: 0; 
    color: #fff; 
}
.footer .nav .nav-link svg * {
    color: #fff;
}
.footer .nav .nav-link:last-child {
    margin-bottom: 0;
}
.footer .newsletterForm .form-control {
    height: 2.75rem;
    border-radius: 0;
}
.footer .newsletterForm .form-control:focus {
    border: none;
    box-shadow: none;
} 
.footer .newsletterForm .btn.btn-global {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0;
    height: 2.75rem;
    color: #fff;
} 
.footer .followIcons a {
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #ddd;
    border-radius: 50%;
    text-align: center;
    line-height: 2.5;
    margin-right: 0.5rem;
    cursor: pointer;
}
.footer .followIcons a::marker {
    font-size: 0;
}
.footer .followIcons a:hover {
    background: var(--globalBlue); 
    transition: 1s;
}
.footer .followIcons a::before {
    display: none;
} 
.footer .border-top-blue-1 {
    border-top: 1px solid #3959AD;
}
.footer .footer_logo {
    font-size: 1.25rem; 
    margin-bottom: 1.25rem;
    color: #fff;
}
.footer .footer_logo + p {
    font-weight: 300;
    margin: 0;
    color: #fff;
}
.footer .footer_header {
    font-size: 1.125rem;
    margin-bottom: 1rem;
    color: #fff;
}
.footer .footerList {
    padding-left: 0.625rem;
}
.footer .footerList li {
    font-size: 0.875rem;
    line-height: 1;
    cursor: pointer;
    font-weight: 300;
    margin-bottom: 1rem;
}
.footer .footerList li:last-child {
    margin-bottom: 0;
}
.footer .footerList li::marker {
    font-size: 0;
}
.footer .footerList li svg {
    margin-right: 0.375rem;
} 
.footer .topFooter .nav-link {
    line-height: 2.5rem;
}
.footer .topFooter .nav-link svg {
    margin-right: 0.375rem; 
}
.footer .topFooter .nav-link::after, 
.footer .topFooter .nav-link::before {
    display: none;
}
.footer .topFooter .followIcons .nav-link  svg {
    margin-right: 0rem; 
} 
.footer .bottomFooter .nav-link {
        margin-bottom: 0.635rem;
}
.footer .bottomFooter .nav-link ::before {
    display: none;
}
.footer .bottomFooter .nav-link  svg {
    margin-right: 0.375rem; 
}
.footer .bottomFooter .nav-link :last-child {
    margin-bottom: 0;
} 
.footer_copyright_text {
    background: #00000020;
}

@media (max-width: 767px) {
    .footer .bottomFooter .nav-link svg {display: none;}
}