.recruiter_brand ul {
    list-style-type: none;
}
.recruiter_brand ul li {
    background: url('../../../public/shapeOne.png') no-repeat 0 0 / 100%;
    margin-bottom: 2.5rem;
    padding: 20px;
    min-height: 137px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
}
.recruiter_brand ul li img {
    width: 80%;
    margin: auto;
}
.topRecruiter_wrapper {padding-top: 100px;}
.topRecruiter_wrapper h3 {margin-bottom: 50px; font-weight: bold;}
.topRecruiter_wrapper h3 span {
    position: relative;
}
.topRecruiter_wrapper h3 span::before {
    content: '';
    width: 100px;
    height: 2px;
    background: #222;
    position: absolute;
    left: -120px;
    top: 18px;
}
.topRecruiter_wrapper h3 span::after {
    content: '';
    width: 100px;
    height: 2px;
    background: #222;
    position: absolute;
    right: -120px;
    top: 18px;
}

@media (max-width: 767px) {
    .topRecruiter_wrapper {
        padding: 50px 0; 
    }
    .recruiter_brand ul li {
        margin-bottom: 1rem;
        padding: 0;
        min-height: 95px;
        width: 45%;
    }
    .topRecruiter_wrapper h3 span {
        font-size: 16px;
    }
    .topRecruiter_wrapper h3 span::before,
    .topRecruiter_wrapper h3 span::after {
        top: 11px;
    }
}