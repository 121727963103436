.recommended_Job_row {
    padding: 10px 1rem 1rem;
}
.recomJob {
    font-weight: 500;
}
.recomJob span {
    border: 1px solid #ddd;
}
.sortBy {color: #666;}
.recomJob span {
    border: 1px solid #ddd;
    padding: 4px 10px;
    border-radius: 22px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
}
.sortBy img {
    width: 14px;
    margin-left: 10px;
}

.searchTopWrap .d-flex {
    justify-content: center;
}