.jobFilter {
    background: #fff;
    border-radius: 10px; 
}
.jobFilter .filterWrap {
    padding: 20px;
}
.filterHeading {padding: 20px 20px 1rem; border-bottom: 1px solid #ddd;}
.filterHeading h3 {font-size: 18px; font-weight: 500; margin-bottom: 0; line-height: 1;}
.filterHeading a {font-size: 14px; font-weight: 400; color: #BE6262 !important; cursor: pointer; text-decoration: underline !important; line-height: 1;}
.filterWrap .form-label {
    font-weight: 500;
}
.filterWrap .form-select {padding: 10px 12px 10px 32px; background: url('../../../public/location-icon.png') no-repeat 10px center / 14px, url('../../../public/downArrow-icon.png') no-repeat 95% center / 15px;}
.filterWrap .form-select:focus {
    box-shadow: none;
    outline: none;
    border-color: #222;
}
.jobFilter .form-check-label {
    padding-left: 10px;
}
.jobType {
    border-bottom: 1px solid #ddd;
}
.jobType:last-child {
    border: none;
}
.jobFilter .form-check-input[type=radio] {
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .jobFilter .form-check-label { 
        line-height: 25px;
    }
}

@media (max-width: 767px) {
    .jobFilter .form-check-label { 
        line-height: 26px;
    }
    .filterWrap .form-label {
        display: block;
    }
    .filterWrap .jobType .form-field {
        display: inline-block;
        width: 49%;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .filterWrap .jobType .form-field {
        width: 24%;
    }
}