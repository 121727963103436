.notificationWraper h1 span::before {
    content: '';
    width: 100px;
    height: 2px;
    background: #222;
    position: absolute;
    left: -120px;
    top: 18px;
}
.notificationWraper h1 span::after {
    content: '';
    width: 100px;
    height: 2px;
    background: #222;
    position: absolute;
    right: -120px;
    top: 18px;
}
.notificationWraper tr th, 
.notificationWraper tr td { 
    padding: 13px 20px;
    vertical-align: middle;
    min-height: 50px;
    border: 1px solid #0000000f;
}
.notificationWraper tr:hover td { 
    background: #d7d7d7;
}
.notificationWraper tr th {
    background: #1b468442;
    font-weight: 600;
}
.notificationWraper tr:nth-child(even) td {
    background: #0000000f;
}
.notificationWraper tr th {
    padding: 16px 20px;
}
.notificationWraper tr td .btn.btn-danger,
.notificationWraper tr td .btn.btn-success {
    min-width: 90px;
    border-radius: 6px;
    padding: 4px;
}

.MeetLink {
    max-width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}