.header .navbar-toggler {
    border: 1px solid var(--whiteColor);
    border-radius: 0;
}
.header .navbar-toggler:focus {
    box-shadow: none;
}
.header .navbar-toggler .navbar-toggler-icon {
    color: var(--whiteColor);
    width: 25px;
    height: 24px;
    background-image: url('../../../public/menu.png') !important;
} 
.header .navbar {
    padding: 0.5rem 0;
}
.header .navbar .navbar-brand {
    margin: 0 0.5rem;
    color: var(--whiteColor);
}
.header .navbar .navbar-brand:hover {
    color: var(--whiteColor);
} 
.header .navbar .navbar-nav .nav-link {
    color: var(--whiteColor);
    margin: 0 0.5rem; 
}
.header .navbar .navbar-nav .nav-link svg {
    color: var(--whiteColor);
}
.header .navbar .navbar-nav .nav-link svg path {
    color: var(--whiteColor);
}  
#profile::after,
#profile::before {
    position: static;
}


@media (min-width: 991px) {
    .header .navbar {
        padding: 1.25rem 0;
    }
}