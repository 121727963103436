
.banner_wrapper {
    background: #eee;
}
.banner_wrapper .searchWrapper {
    width: 700px;
    background: #fff;
    padding: 8px;
    border-radius: 50px; 
}
.banner_wrapper .searchWrapper .btn {
    background: var(--globalBlue);
    min-width: 114px;
    height: 45px;
    border-radius: 30px;
    color: #fff;
    font-size: 12px;
}
.banner_wrapper .searchWrapper .form-control {
    padding-left: 46px;
    border: none; 
    background: url('../../../public/search-icon.png') no-repeat 1rem center / 15px;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
} 
.bannerWrapper {
    position: relative;  
}
.bannerImage {
    position: relative;
}
.bannerImage::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(38 38 38 / 45%);
    left: 0;
}
.searchTopWrap .searchWrapper .form-control {
    padding-left: 46px;
    border: none; 
    background: url('../../../public/search-icon.png') no-repeat 1rem center / 15px;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
} 
.banner_wrapper .banner_caption { 
    padding: 50px 20px;
    width: 100%; 
    margin: auto;
}

.searchWrapper {
    width: 700px;
    background: #fff;
    padding: 8px;
    border-radius: 50px;
}

.banner_wrapper .banner_caption .banner-heading {
    max-width: 100%;
    text-align: center;
}

.banner_wrapper .banner_caption .banner-heading h1 {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.banner_wrapper .banner_caption .banner-heading h1 span {
    color: #222;
}

.banner_wrapper .banner_caption .banner-heading h2 {
    color: #222;
    font-size: 1rem;
}

.jobSearch_caption {
    background: rgba(255, 255, 255, 0.8);
    padding: 1.25rem 2rem;
    border-radius: 1.25rem;
    box-shadow: 1px 1px 10px #333;
}

.jobSearch_caption .form-group {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.jobSearch_caption .form-group .form-control {
    padding: 0.575rem 0.875rem;
}

.jobSearch_caption .form-group .form-control .btn {
    width: 300px;
    background: var(--globalBlue);
    border: none;
    padding: 0.575rem 0.875rem;
}

@media (min-width: 991px) {
    .header .navbar {
        padding: 1.25rem 0;
    }
    .banner_wrapper .banner_caption {
        padding: 80px;
        width: 75%;
    }
}





#home {
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
@media (max-width: 767px) {
    .banner_wrapper .searchWrapper .form-control {font-size: 14px;}
    .bg-home2 h1 {
        font-size: 20px;
    }
    .bg-home2 h1 span {
        display: block;
    }
    .bg-home2 p.lead {
        font-size: 16px;
    }
}
.filter-search-form {
    position: relative;
}
.filter-search-form i {
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    left: 12px;
    z-index: 1;
    color: red;
    font-size: 20px;
}
.registration-form {
    position: relative;
}
.registration-form .submit-btn {
    border-radius: 8px;
}
.registration-form .form-control, .registration-form .choices[data-type*=select-one] .choices__inner {
    border-radius: 8px;
}
@media (min-width: 992px) {
    .filter-border::before {
        content: "";
        border-right: 1px solid red;
        position: absolute;
        right: 4px;
        height: 38px;
        top: 10px;
        z-index: 1;
   }
    .registration-form {
        position: relative;
   }
    .registration-form .submit-btn {
        border-radius: 0px 8px 8px 0px;
   }
    .registration-form .form-control {
        border-radius: 8px 0px 0px 8px;
   }
    .registration-form .choices[data-type*=select-one] .choices__inner {
        border-radius: 0;
   }
}
.filter-input-box, .filter-input-box.form-select, .filter-search-form .choices__inner {
    box-shadow: none !important;
    border: 0;
    font-size: 15px;
    height: 60px;
    padding: 16px 6px 15px 45px;
    border-radius: 0;
}
.treding-keywords li a {
    color: rgba(255, 255, 255, 0.70);
    transition: all 0.5s ease;
}
.treding-keywords li a:hover {
    color: #fff;
}
.bg-home2 {
    padding: 100px 0 140px 0;
    background-color: rgba(118, 109, 244, 0.1);
    overflow-x: clip;
}
@media (max-width: 767px) {
    .bg-home2 {
        padding: 40px 0 50px 0;
   }
}
@media (max-width: 991.98px) {
    .home-img {
        height: auto;
        width: 100%;
   }
}
.bg-home3 {
    padding: 210px 0 95px 0;
    background-color: rgba(118, 109, 244, 0.1);
    overflow-x: clip;
}
@media (max-width: 767px) {
    .bg-home3 {
        padding: 160px 0 90px 0;
   }
}
.homeslider .home-slide-box {
    position: relative;
}
.homeslider .home-slide-box .bg-overlay {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
}
.homeslider .home-slide-box .home-slide-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
@media (min-width: 992px) {
    .homeslider {
        width: 200%;
   }
    .homeslider .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
   }
    .homeslider .swiper-slide {
        width: 60%;
        height: 100%;
   }
    .homeslider .swiper-slide:nth-child(2n) {
        width: 60%;
        height: 100%;
   }
    .homeslider .swiper-slide:nth-child(3n) {
        width: 60%;
        height: 100%;
   }
}
.quote-icon .icon {
    position: absolute;
    top: -32px;
    left: 7px;
    z-index: 9;
    font-size: 102px;
    color: #fff;
}
.quote-icon .icon-2 {
    font-size: 100px;
    position: absolute;
    top: -31px;
    left: 6px;
    z-index: 9;
}
@media (min-width: 768px) {
    .bg-home2 .filter-border::before, .bg-home3 .filter-border::before {
        content: "";
        border-right: 1px solid red;
        position: absolute;
        right: 4px;
        height: 38px;
        top: 10px;
        z-index: 1;
   }
    .bg-home2 .registration-form .submit-btn, .bg-home3 .registration-form .submit-btn {
        border-radius: 0px 8px 8px 0px;
   }
    .bg-home2 .registration-form .form-control, .bg-home3 .registration-form .form-control {
        border-radius: 8px 0px 0px 8px;
   }
    .bg-home2 .registration-form .choices[data-type*=select-one] .choices__inner, .bg-home3 .registration-form .choices[data-type*=select-one] .choices__inner {
        border-radius: 0PX;
   }
}

.shape {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
}
.shape>svg {
    width: 100%;
    height: auto;
}