.paginationWrapper .pagination {
    justify-content: center;
}
.paginationWrapper .pagination .page-item .page-link {
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    color: #222;
    font-weight: 500;
    margin: 0 3px;
    background: transparent;
}
.paginationWrapper .pagination .page-item.active .page-link {
    box-shadow: 0px 2px 5px #ccc;
    background: #fff;
}
.paginationWrapper .pagination .page-item.leftArrow a {
    background: url('../../../public/leftArrow.png') no-repeat center / contain;
    padding: 0;
    width: 50px;
    height: 40px;
    box-shadow: none;
}
.paginationWrapper .pagination .page-item.rightArrow a {
    background: url('../../../public/rightArrow.png') no-repeat center / contain;
    padding: 0;
    width: 50px;
    height: 40px;
    box-shadow: none;
}
.paginationWrapper .pagination .page-item.leftArrow a::after,
.paginationWrapper .pagination .page-item.leftArrow a::before {
    display: none;
}
.paginationWrapper .pagination .page-item a span {
    display: none;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .paginationWrapper .pagination .page-item .page-link {
        line-height: 26px;
    }
    .paginationWrapper .pagination .page-item.rightArrow a {
        width: 40px;
        height: 35px;
    }
    .paginationWrapper .pagination .page-item.leftArrow a {
        width: 40px;
        height: 35px;
    }
}

