.jobIntrestedWrapper {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1.25rem;
    box-shadow: 0px 3px 10px #ddd;
}
.jobIntrestedWrapper h3 {
    font-size: 20px;
    font-weight: 500;
}
.intrestedProfileRow {
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
}
.intrestedProfileRow h4 {
    font-size: 16px;
}
.jobIntrestedWrapper .btn {
    padding: 8px 30px;
    border-radius: 30px;
    color: #fff;
}
.intrestedProfileRow h4 + span {
    color: #666; display: block;
    font-size: 15px;
}
.intrestedProfileRow .rating {
    font-size: 14px;
}
.intrestedProfileRow .rating span {
    color: #666;
}
.intrestedProfileRow .location {
    font-size: 14px;
    line-height: 19px;
}
.intrestedProfileRow .location span {
    display: inline-block;
    vertical-align: text-bottom;
}
.intrestedProfileRow .location span img {
    width: 1rem;
}
.intrestedProfileRow .postedDated {
    position: absolute;
    right: 0;
    font-size: 12px;
    bottom: 28px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .jobIntrestedWrapper h3 {
        font-size: 18px;
    }
    .intrestedProfileRow h4 {
        font-size: 14px;
    }
    .intrestedProfileRow h4 + span {
        font-size: 13px;
    }
    .intrestedProfileRow .rating {
        font-size: 14px;
    }
    .intrestedProfileRow .location {
        font-size: 13px;
        line-height: 19px;
    }
    .intrestedProfileRow .postedDated {
        position: absolute;
        right: 0;
        font-size: 10px;
        bottom: 84px;
    }
}