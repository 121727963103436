
.latestJob_Wrapper {
    background: url('../../../public/latestJobShape.png') no-repeat center top /cover;
    padding: 100px 0;
    min-height: 1550px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.latestJob_Wrapper h3 {margin-bottom: 50px; font-weight: bold;}
.latestJob_Wrapper h3 span {
    position: relative;
}
.latestJob_Wrapper h3 span::before {
    content: '';
    width: 100px;
    height: 2px;
    background: #222;
    position: absolute;
    left: -120px;
    top: 18px;
}
.latestJob_Wrapper h3 span::after {
    content: '';
    width: 100px;
    height: 2px;
    background: #222;
    position: absolute;
    right: -120px;
    top: 18px;
} 

.latestJob_Wrapper .cardSlider-Wrapper .card {
    max-width: 574px;
    margin: auto;
    border: none;
    box-shadow: 1px 6px 10px rgb(0 0 0 / 16%);
}
.latestJob_Wrapper .cardSlider-Wrapper .card h4 {
    font-weight: bold; 
    margin-bottom: 20px; 
    font-size: 20px;
}
.latestJob_Wrapper .cardSlider-Wrapper .card h4 span {
    float: right; 
    font-size: 16px;
}
.latestJob_Wrapper .cardSlider-Wrapper .jobDetails {
    max-width: 100%;
    font-weight: 400;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 44px;
}
.latestJob_Wrapper .cardSlider-Wrapper .card .btn.btnJobDetail {
    background: #8197CA;
    max-width: 100px;
    font-size: 13px;
    color: #fff;
    padding: 4px 12px;
    height: 30px;
    min-width: 80px;
}
.latestJob_Wrapper .cardSlider-Wrapper ul li {
    margin-right: 0;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    display: flex;
    margin-bottom: 1rem;
    flex-basis: 50%;
}
.latestJob_Wrapper .cardSlider-Wrapper ul li img {
    width: 1rem; 
    margin-right: 7px; 
    margin-top: -3px;
}
.cardSlider-Wrapper {
    max-height: 804px;
    overflow: hidden;
    padding-left: 20%;
}

.cardSlider-Wrapper .slick-prev {
    position: absolute;
    left: -50px;
    top: 30%;
    background: url('../../../public/leftArrow.png') no-repeat 0 0 !important;
    border: none;
    text-indent: -116px;
    width: 40px;
    height: 40px;
    transform: rotate(90deg);
    color: transparent;
}
.cardSlider-Wrapper .slick-next {
    position: absolute;
    left: -47px;
    top: 43%;
    background: url('../../../public/leftArrow.png') no-repeat 0 0 !important;
    border: none;
    text-indent: -116px;
    width: 40px;
    height: 40px;
    transform: rotate(270deg);
    color: transparent;
}
.cardSlider-Wrapper .slick-next::before,
.cardSlider-Wrapper .slick-prev::before {
    display: none;
}
.cardSlider-Wrapper .slick-vertical {
    padding: 0 20px 20px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .cardSlider-Wrapper { 
        padding-left: 10%;
    }
    .latestJob_Wrapper {
        padding: 0;
        min-height: 1150px; 
    }
}

@media (max-width: 767px) { 
    .latestJob_Wrapper .cardSlider-Wrapper .card .btn.btnJobDetail.applyBtn {
        position: absolute;
        right: 20px;
        bottom: 16px;
    }
    .latestJob_Wrapper {
        background-position: top;
        padding: 50px 0 0; 
        min-height: auto;
    }
    .cardSlider-Wrapper {
        padding-left: 0;
        overflow: auto;
        max-height: fit-content;
        padding-bottom: 100px;
    }
    .latestJob_Wrapper .cardSlider-Wrapper .jobDetails {
        max-width: 100%;
    }
    .cardSlider-Wrapper .slick-prev {
        transform: none;
        top: auto;
        bottom: -50px;
        background: url('../../../public/leftArrow.png') no-repeat 0 0 !important;
        left: 30%;
    }
    .cardSlider-Wrapper .slick-next {
        transform: none;
        top: auto;
        bottom: -48px;
        background: url('../../../public/leftArrow.png') no-repeat 0 0 !important;
        left: 60%;
        transform: rotate(180deg);
    }
    .cardSlider-Wrapper .slick-prev::before,
    .cardSlider-Wrapper .slick-next::before {
        display: none;
    }
    .latestJob_Wrapper h3 span {
        font-size: 16px;
    }
    .latestJob_Wrapper h3 span::before,
    .latestJob_Wrapper h3 span::after {
        top: 9px;
    } 
    .latestJob_Wrapper .cardSlider-Wrapper .card h4 {
        font-size: 18px;
    }
    .latestJob_Wrapper .cardSlider-Wrapper .card h4 span {
        font-size: 12px;
        margin-top: 5px;
    }
    .latestJob_Wrapper .latestJobImage {
        max-width: 70%;
        margin: 0 auto 25px;
    }
}

@media (min-width: 1600px) {
    .latestJob_Wrapper {
        min-height: 1530px;
    }
}

@media (min-width: 1800px) {
    .latestJob_Wrapper {
        min-height: 1400px;
    }
}