.mentor_Wrapper {
    background: url('../../../public/mentorSectionBg.png') no-repeat center top / cover;
    padding: 100px;
}

.mentor_Wrapper .mentorSection {
    width: 400px;
    position: relative;
    box-shadow: -10px 10px 50px rgb(0 0 0 / 34%);
    background: #fff;
    margin: 0 55px;
    border-radius: 10px;
    padding: 110px 30px 40px;
}
.mentor_Wrapper h3 {margin-bottom: 160px; font-weight: bold;}
.mentor_Wrapper h3 span {
    position: relative;
}
.mentor_Wrapper h3 span::before {
    content: '';
    width: 100px;
    height: 2px;
    background: #222;
    position: absolute;
    left: -120px;
    top: 18px;
}
.mentor_Wrapper h3 span::after {
    content: '';
    width: 100px;
    height: 2px;
    background: #222;
    position: absolute;
    right: -120px;
    top: 18px;
} 
.mentor_Wrapper .mentorSection .mentorProfile h3 {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 1rem;
}   
.mentor_Wrapper .mentorSection .mentorProfile h4 {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 1rem;
}
.mentor_Wrapper .mentorSection .mentorProfile p {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 3.25rem;
}
.mentor_Wrapper .mentorSection .mentorProfile .userProfile {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -72px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 10px solid #ddd;
    box-shadow: 1px 1px 10px #ccc;
}

.mentor_Wrapper .mentorSection .mentorProfile .btn.btn-connect {
    background: #8296CB;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    color: #fff;
    /* box-shadow: 0 4px 4px rgba(0,0,0,0.25); */
}

@media (min-width: 768px) and (max-width: 991px) {
    .mentor_Wrapper {padding: 0;}
    .mentor_Wrapper .mentorSection { 
        margin: 0 10px 50px; 
    }
    .mentor_Wrapper h3 {
        margin-bottom: 100px; 
    }
}

@media (max-width: 767px) {
    .mentor_Wrapper {
        padding: 0 0 50px;
        background: none;
    }
    .mentor_Wrapper .mentorSection {  
        box-shadow: none;
        background: #fff;
        margin: 90px 0 0;
        border-radius: 10px;
        padding: 100px 10px 0;
    }
    .mentor_Wrapper .mentorSection .mentorProfile p {
        font-size: 14px;
        margin-bottom: 40px;
        height: 160px;
    }
    .mentor_Wrapper h3 {
        margin-bottom: 30px;
    }
    .mentor_Wrapper h3 span {
        font-size: 16px;
    }
    .mentor_Wrapper h3 span::before,
    .mentor_Wrapper h3 span::after {
        top: 11px;
    }
    .mentor_Wrapper .slick-next {
        transform: none;
        top: auto;
        bottom: 73px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAlCAYAAADr2wGRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQqSURBVHgB3ZltaFtVGMf/576U6vYhzpe1OiSCXwZN2ujmUHRUweEXcTo/a6tWhMHMp6kMbKPrRBFsERwy1BSdIgXthIGILxkTUXFmSSdM7Fg264JCobKxrcvNOXue2+Uu6Vtyz71pYT9acs+5Oefc/32e8/I8EdDkZDYbsW07KqSMGkDErSyXC2Xbnl7X0XEUy4jw82V+8NWW9aICuvm/TscZKeUBJeVYeyJRQBNpSERxfLzbBPrrPfiigwiRLpdKqWaJWVIEv/lVptmvhEgiBAwhhs6RmDsSiWmEyKIiitls1LDtH6BUFKGOKAqyVHowTKsYC1X+l812mZaVDV0AQ33yy5k8dqwLITHPEmwBFqAqK07zmJaOkwjDIjWWqLjQMghgIjwWzzsEpEaEadv9TXGhxaCxVvOYAfFEFPP5HqVUD0Jg38G/sH34F5w4c7bud6VSSfKAbgTAqlwYhsFWQFD27B/H/m9PzvYpBN7dcU/dNjQH2RoZaOJagjezMNyoWgBz283XN9SON9Eg1nBFmEI8jYAMflIr4JGNtyK5bX3D7YVlbYUms3NCKe0OGBbw6XdXBWxafxMGn0ugtcVsuA9a67VfpMGuFGRJHUjnagRs7lyLD3fe50vAFSKTtMlCAyPIXNhNFhg9dMorbyEX2pvcBF1M09QSYbkihK8Tucvuj/P47PuCV3747jYMPtuF8zOOVze3V1FdK+bcob+ZknNnNDrQ6j2ctWbB5XIicdjB6GjZa13M5dK0vPryx7c//wMffX0CKwntBi9PHdn1Jl8bGu1XXABDMcrzlWuDrOj7bP/E/bdjpZFCjlSueccuwCevk++zD39x+LRX90DsFrz1wga0WFXOriofam7VfOjG2QuXXnqoL7N37i0ZOV8zg9ZM3TgzMbFjplIW/+bzW8k2X0KDV/b9jq9+mvTKWza0453tG6ELHc05WMrAJ0ZLuZyBJm/03YVH713nlb/5rYid7x+BLhcBrSyJcQPFuyrA4WtPXwKPV82Rgz//g10fZOEXzo7oxt7u6kQiDkETPqm+9kwntm2+KmTsx79dISVHNtxPWakRaOKKaHWcIZ1VyuuEhfR2zRPy3oE/G+1iGgHc2hXBLkXb3zACwkKerBLy6/GpxhpKORwk1vaCIrZGaTa7FyjmTZGQVdfZOH76f7z6VLx+A6UKlClMIwA162+Q5VYXCk972+PxNAJQc+xYG4+P0VRMYbmQMhVUALPg8ZUskkYI0d5SkNsOt8Vi4aRHF6oki/Q01SJkgbAEMEsGEmdyuaRFWZCwkmm8jEshUm0dHUMIkbrREGcFKeQaMAK6F+/IZcfpbUZ6v+GQriKGMiOPNWoZfvMU543Q51h7LJZBk/Afl+JKnkrKbooIO1kQBShR94ZSvOsXaOM8xQ9+0XGOhv1bxDXLZUhXi3IVVlqRAAAAAElFTkSuQmCC') no-repeat 0 0 !important;
        right: 30%;
        transform: rotate(180deg);
        width: 40px;
        height: 40px;
        color: transparent; 
        z-index: 9;
    }
    .mentor_Wrapper .slick-next::before,
    .mentor_Wrapper .slick-prev::before {
        display: none;
    }
    .mentor_Wrapper .slick-prev {
        transform: none;
        top: auto;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAlCAYAAADr2wGRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQqSURBVHgB3ZltaFtVGMf/576U6vYhzpe1OiSCXwZN2ujmUHRUweEXcTo/a6tWhMHMp6kMbKPrRBFsERwy1BSdIgXthIGILxkTUXFmSSdM7Fg264JCobKxrcvNOXue2+Uu6Vtyz71pYT9acs+5Oefc/32e8/I8EdDkZDYbsW07KqSMGkDErSyXC2Xbnl7X0XEUy4jw82V+8NWW9aICuvm/TscZKeUBJeVYeyJRQBNpSERxfLzbBPrrPfiigwiRLpdKqWaJWVIEv/lVptmvhEgiBAwhhs6RmDsSiWmEyKIiitls1LDtH6BUFKGOKAqyVHowTKsYC1X+l812mZaVDV0AQ33yy5k8dqwLITHPEmwBFqAqK07zmJaOkwjDIjWWqLjQMghgIjwWzzsEpEaEadv9TXGhxaCxVvOYAfFEFPP5HqVUD0Jg38G/sH34F5w4c7bud6VSSfKAbgTAqlwYhsFWQFD27B/H/m9PzvYpBN7dcU/dNjQH2RoZaOJagjezMNyoWgBz283XN9SON9Eg1nBFmEI8jYAMflIr4JGNtyK5bX3D7YVlbYUms3NCKe0OGBbw6XdXBWxafxMGn0ugtcVsuA9a67VfpMGuFGRJHUjnagRs7lyLD3fe50vAFSKTtMlCAyPIXNhNFhg9dMorbyEX2pvcBF1M09QSYbkihK8Tucvuj/P47PuCV3747jYMPtuF8zOOVze3V1FdK+bcob+ZknNnNDrQ6j2ctWbB5XIicdjB6GjZa13M5dK0vPryx7c//wMffX0CKwntBi9PHdn1Jl8bGu1XXABDMcrzlWuDrOj7bP/E/bdjpZFCjlSueccuwCevk++zD39x+LRX90DsFrz1wga0WFXOriofam7VfOjG2QuXXnqoL7N37i0ZOV8zg9ZM3TgzMbFjplIW/+bzW8k2X0KDV/b9jq9+mvTKWza0453tG6ELHc05WMrAJ0ZLuZyBJm/03YVH713nlb/5rYid7x+BLhcBrSyJcQPFuyrA4WtPXwKPV82Rgz//g10fZOEXzo7oxt7u6kQiDkETPqm+9kwntm2+KmTsx79dISVHNtxPWakRaOKKaHWcIZ1VyuuEhfR2zRPy3oE/G+1iGgHc2hXBLkXb3zACwkKerBLy6/GpxhpKORwk1vaCIrZGaTa7FyjmTZGQVdfZOH76f7z6VLx+A6UKlClMIwA162+Q5VYXCk972+PxNAJQc+xYG4+P0VRMYbmQMhVUALPg8ZUskkYI0d5SkNsOt8Vi4aRHF6oki/Q01SJkgbAEMEsGEmdyuaRFWZCwkmm8jEshUm0dHUMIkbrREGcFKeQaMAK6F+/IZcfpbUZ6v+GQriKGMiOPNWoZfvMU543Q51h7LJZBk/Afl+JKnkrKbooIO1kQBShR94ZSvOsXaOM8xQ9+0XGOhv1bxDXLZUhXi3IVVlqRAAAAAElFTkSuQmCC') no-repeat 0 0 !important;
        left: 30%;
        border: none;
        text-indent: -116px;
        width: 40px;
        height: 40px;
        color: transparent;
        bottom: 70px;
        z-index: 9;
    }
}