/* .registrationBanner {
    background-image: linear-gradient(180deg, rgb(217, 217, 217), rgb(217 217 217 / 0%));
} */
.loginForm {
    max-width: 400px;
    margin: 76px auto;
}
.loginForm * {
    color: #222;
}
.validation-error{
    color: red;
    font-size: 14px;
}
.loginForm h3 {
    font-size: 1.875rem;
    font-weight: bold;
}
.LoginGoogle {
    padding: 0.625rem;
    box-shadow: 1px 1px 5px #00000021; 
    border-radius: 6px; 
    font-weight: 500;
}
.LoginGoogle img {
    position: absolute; 
    left: 1rem;
    top: 0.875rem;
}
.LoginEmail::after {
    content: '';
    position: absolute;
    width: 111px;
    height: 2px;
    background: #d9d9d9;
    right: 0;
    top: 8px;
}
.LoginEmail::before {
    content: '';
    position: absolute;
    width: 111px;
    height: 2px;
    background: #d9d9d9;
    left: 0;
    top: 8px;
}
.form-field .form-label {
    font-size: 0.875rem;
    font-weight: 500;
}
.form-field .form-control {
    padding: 0.625rem 1rem;
    border-color: lightgrey;
}
.form-field .form-control:focus {
    box-shadow: none; 
    border-color: #143e85;
}
.btn.btn-primary {
    background: var(--globalBlue);
    padding: 0.625rem;
    border: none;
}
.form-check-input[type=checkbox]:focus {
    border-color: none;
    outline: 0;
    box-shadow: none;
}
.form-check-input[type=checkbox] {
    position: relative;
    border: none;
    outline: none;
}
.form-check-input[type=checkbox]::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    opacity: 1;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    top: -2px;
    box-shadow: none;
    outline: none;
}
.form-check-input[type=checkbox]:checked::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #143e85 url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
    opacity: 1;
    border-radius: 4px;
    top: -2px;
    border: none;
}
.form-check-label {
    font-size: 14px;
}
.textLink a {
    color: #222;
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
    .LoginEmail::after,
    .LoginEmail::before {
        width: 90px;
    }
}

@media (max-width: 767px) {
    .loginForm { 
        margin: 7px auto 20px; 
        max-width: 500px;
    }
    .loginForm h3 {
        font-size: 1.5rem;
    }
    .LoginGoogle img {
        width: 1rem;
    }
    .LoginEmail::after,
    .LoginEmail::before {
        top: 11px;
        width: 25%;
    }
}