.otpInput {
    gap: 16px; 
}
.otpInput input {
    width: 60px !important;
    height: 52px;
    border-radius: 4px;
    border: 1px solid rgba(97, 96, 96, 0.2);
}
.otpInput input:not(:placeholder-shown) {
    border: 1px solid #00AEEF;
} 
.otpInput input:focus,
.otpInput input:active,
.otpInput input:hover { 
    border-color: #00AEEF;
}
.otpInput input:focus ~ label,
.otpInput input:active ~ label,
.otpInput input:hover ~ label {
    transform: translateY(-38px);
    font-size: 14px;
    z-index: 3;
    background: #fff;
    padding: 0 2px;
    top: 56%;
}