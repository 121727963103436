.jobDetailWrapper {
    background: #f3f3f3;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    background: linear-gradient(180deg, rgba(20, 81, 135, 1), #f3f3f3, #f3f3f3, #f3f3f3, #f3f3f3,#f3f3f3, #f3f3f3,#f3f3f3, #f3f3f3 90%);
}

@media (max-width: 767px) {
    .jobDetailWrapper {
        padding-top: 20px;
    }
}