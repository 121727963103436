.pageNotFound_Wrapper h2 {
    font-size: 70px;
    font-weight: bold;
    color: #222e83;
    position: relative;
}
.pageNotFound_Wrapper h2 span {
    position: absolute;
    font-size: 70px;
    font-weight: bold;
    color: #222e83;
}
.pageNotFound_Wrapper p {
    font-size: 18px;
    color: #333;
    margin-bottom: 25px;
}
.pageNotFound_Wrapper a {
    padding: 10px;
}
.pageNotFound_text {flex-basis: 40%;}
.pageNotFound_Wrapper .pageNotFound_image {
    flex-basis: 60%;
}

.pageNotFound_Wrapper {
    margin: 50px 0 200px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}