/* .jobsCategory_Wrapper {padding-top: 100px;} */
.jobsCategory_Wrapper h3 {margin-bottom: 30px; font-weight: bold;}
.jobsCategory_Wrapper h3 span {
    position: relative;
}
.jobsCategory_Wrapper h3 span::before {
    content: '';
    width: 100px;
    height: 2px;
    background: #222;
    position: absolute;
    left: -120px;
    top: 18px;
}
.jobsCategory_Wrapper h3 span::after {
    content: '';
    width: 100px;
    height: 2px;
    background: #222;
    position: absolute;
    right: -120px;
    top: 18px;
}
.jobsCategory_Wrapper .jobsCategory_row {
    gap: 65px; padding-right: 30%;
}
/* .jobsCategory_Wrapper .jobsCategory_row .jobsCategory {
    padding-top: 70px; margin-top: 40px;
} */
.jobsCategory_Wrapper .jobsCategory_row .jobsCategory .recruiter {
    max-width: 415px;
    background: url('../../../public/jobCategoryShape.png') no-repeat 0 0 / cover;
    padding: 7rem 5rem 1rem;
    min-height: 415px;
}
.jobsCategory_Wrapper .jobsCategory_row .jobsCategory .recruiter h4 {
    font-size: 20px;
    margin-bottom: 1rem;
    font-weight: 600;
    display: inline-block;
    border-bottom: 2px dashed #333;
}
.jobsCategory_Wrapper .jobsCategory_row .jobsCategory .recruiter p {
    margin-bottom: 0;
    font-size: 14px;
}

.jobsCategory_Wrapper .jobsCategory_row .jobsCategory .hiredImage {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
}
.jobsCategory_Wrapper .jobsCategory_row .jobsCategoryImage {
    position: absolute;
    right: -7%;
    top: 10%;
    width: 500px;
}

@media (max-width: 767px) {
    .jobsCategory_Wrapper .jobsCategory_row .jobsCategory .recruiter p {
        font-size: 16px;
    }
    .jobsCategory_Wrapper h3 span {
        font-size: 16px;
    }
    .jobsCategory_Wrapper h3 span::before,
    .jobsCategory_Wrapper h3 span::after {
        top: 11px;
    }
    .jobsCategory_Wrapper {
        /* padding-top: 50px; */
        padding-bottom: 0;
    }
    .jobsCategory_Wrapper .jobsCategory_row .jobsCategoryImage {
        position: static;
        width: 100%;
        display: none;
    }
    .jobsCategory_Wrapper .jobsCategory_row {
        padding-right: 0;
    }
    .jobsCategory_Wrapper .jobsCategory_row .jobsCategory {
        flex-basis: 100%;
        margin-top: 0; 
        padding-top: 0;
        margin-bottom: 15px;
    }
    .jobsCategory_Wrapper .jobsCategory_row .jobsCategory .recruiter {
        max-width: 415px;
        padding: 2rem 1rem 1.5rem;   /*8rem 1rem 1.5rem */
        min-height: auto;
        background: #ebebeb;
        border-radius: 10px;
        margin: auto;
    }
    .jobsCategory_Wrapper .jobsCategory_row .jobsCategory .hiredImage {
        width: 80px;
        top: 20px;
    }
    .jobsCategory_Wrapper .jobsCategory_row .jobsCategory .recruiter h4 {
        font-size: 16px;
    }
}