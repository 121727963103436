.aboutCompany, .aboutJobs {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1.375rem;
    box-shadow: 0px 3px 10px #ddd;
}
.jobProfileHeading .postedDate {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 15px;
}
.jobProfileHeading h3 {
    font-size: 26px;
    font-weight: bold;
    margin: 0;
}
.jobProfileHeading .jobProfileInner span img {
    width: 14px; margin-right: 5px;
}
.jobProfileHeading .jobProfileInner span.budget {margin-left: 20px;}
.jobProfileHeading .jobProfileInner span.budget img {width: 13px;}
.jobProfileHeading .jobProfileInner {
    line-height: 28px;
}
.jobProfileHeading .btn {
    color: #fff;
    position: absolute;
    right: 30px;
    top: 33px;
    padding: 10px 40px;
    font-size: 14px;
    border-radius: 27px;
}
.jobProfileHeading {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1.375rem;
    box-shadow: 0px 3px 10px #ddd;
}
.aboutCompany label, .aboutJobs label {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 1.25rem;
}
.aboutCompany p {
    margin-bottom: 0;
}
.aboutJobs ul {
    padding-left: 17px;
}
.aboutJobs ul li b, strong {
    font-weight: 500;
}
.keySkills li {
    border: 1px solid #ddd;
    display: inline-block;
    padding: 5px 12px;
    font-size: 14px;
    border-radius: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.jobProfileFixedOnTop {
    position: fixed;
    width: 100%;
    background: #fff;
    left: 0;
    top: 80px;
    box-shadow: 1px 1px 5px #ddd;
    opacity: 0;
    padding: 10px;
    z-index: 9;
    -webkit-animation: smoothScroll1 2s forwards;
          animation: smoothScroll1 2s forwards;
}
@-webkit-keyframes smoothScroll1 {
    0% {
        -webkit-transform: translateY(-40px);
                transform: translateY(-40px);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
  }
  @keyframes smoothScroll1 {
    0% {
        -webkit-transform: translateY(-40px);
                transform: translateY(-40px);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
  }
body.scrolled500 .jobProfileFixedOnTop {
    opacity: 1;
}
.jobProfileFixedOnTop .heading h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
}
.jobProfileFixedOnTop .heading p {
    font-size: 14px;
    margin-bottom: 0;
}
.jobProfileFixedOnTop .btn {
    padding: 10px 30px;
    border-radius: 30px;
    color: #fff;
    font-size: 14px;
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .jobProfileHeading h3 { 
        margin: 0 0 10px;
        padding-right: 120px;
        line-height: 1.2;
    }
    .intrestedProfileRow .postedDated { 
        bottom: 6px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .jobProfileFixedOnTop {
        top: 48px;
    }
    .jobProfileFixedOnTop .heading h3 {
        font-size: 16px;
    }
    .jobProfileFixedOnTop .btn {
        padding: 7px 30px;
    } 
    .jobProfileHeading, .aboutCompany, .aboutJobs, .jobIntrestedWrapper {
        padding: 1rem;
    }
    .jobProfileHeading h3 {
        font-size: 22px;
    }
    .jobProfileHeading .btn { 
        right: 16px;
        top: 75px;
        padding: 7px 30px; 
    }
    .aboutCompany label, .aboutJobs label {
        font-size: 18px; 
        margin-bottom: 1rem;
    }
    .keySkills li { 
        padding: 3px 12px;
        font-size: 12px; 
        margin-right: 7px;
        margin-bottom: 8px;
    }
}

@media (max-width: 767px) {
    .jobProfileFixedOnTop {
        top: 48px;
    }
    .jobProfileFixedOnTop .heading h3 {
        font-size: 16px;
    }
    .jobProfileFixedOnTop .btn {
        padding: 7px 30px;
    } 
    .jobProfileHeading, .aboutCompany, .aboutJobs, .jobIntrestedWrapper {
        padding: 1rem;
    }
    .jobProfileHeading h3 {
        font-size: 22px;
    }
    .jobProfileHeading .btn { 
        right: 16px;
        top: 75px;
        padding: 7px 30px; 
    }
    .aboutCompany label, .aboutJobs label {
        font-size: 18px; 
        margin-bottom: 1rem;
    }
    .keySkills li { 
        padding: 3px 12px;
        font-size: 12px; 
        margin-right: 7px;
        margin-bottom: 8px;
    }
}