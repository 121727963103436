.contactBanner {
    background: #dcf0f3;
    padding-bottom: 150px;
    min-height: 400px;
}
.contactFormWrapper {
    padding-top: 135px;
}
.contactBannerImg {
    position: absolute;
    width: 500px;
    left: 0;
    right: 0;
    margin: auto;
    box-shadow: 1px 1px 10px #ededed;
    border-radius: 70px;
    overflow: hidden;
}
.contactForm {
    max-width: 400px;
    margin: 0 auto 40px;
}
.contactFormWrapper .heading {
    margin: 52px 0;
}
.contactFormWrapper .heading h3 {
    margin: 0;
    line-height: 1;
}
.contactFormWrapper .heading h4 {
    font-size: 70px;
    margin: 0;
    line-height: 1;
    margin-top: -17px;
    color: rgba(190, 190, 190, 0.17);
}

.followUs li {
    font-size: 16px;
    line-height: 2.2;
    position: relative; 
}
.followUs li svg {
    margin-right: 16px; 
} 

.followUsOn .footer_header {
    font-size: 20px;
    font-weight: 500;
}   
.followUsOn .followIcons a {
    border: 1px solid #144F85;
    margin-right: 1rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    cursor: pointer;
    padding: 0;
    line-height: 38px;
}
.followUsOn .followIcons a:hover {
    text-decoration: none !important;
    background: #144F85;
}
.followUsOn .followIcons a:hover a * {
    color: #fff;
}
.followUsOn .followIcons a:hover::after,
.followUsOn .followIcons a:hover::before {
    display: none;
}
.followUsOn .followIcons a * {
    color: #144F85;
}
.followUsOn .followIcons a:hover * {
    color: #fff;
}

@media (max-width: 767px) {
    .contactForm { 
        margin: 0 auto 0;
    }
    .contactBanner {
        padding-bottom: 55px;
        background-position: center;
        min-height: 315px;
    }
    .contactBannerImg { 
        width: 90%; 
        top: 10%;
    }
    .contactFormWrapper {
        padding-top: 70px;
    }
    .contactForm {
        max-width: 100%; 
    }
}