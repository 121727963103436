.searchTopWrap {
    padding: 75px;
}
.searchWrapper {
    width: 700px;
    background: #fff;
    padding: 8px;
    border-radius: 50px; 
}
.searchTopWrap .searchWrapper .btn {
    background: var(--globalBlue);
    min-width: 114px;
    height: 45px;
    border-radius: 30px;
    color: #fff;
    font-size: 12px;
}
.searchTopWrap .searchWrapper .form-control {
    padding-left: 46px;
    border: none; 
    background: url('../../../public/search-icon.png') no-repeat 1rem center / 15px;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
} 
.min-height-220 {min-height: 220px;}

@media (min-width: 768px) and (max-width: 1024px) {
    .min-height-220 {
        min-height: 150px;
    }
}

@media (max-width: 767px) {
    .searchTopWrap {
        padding: 40px 0 20px;
    }
    .searchTopWrap .searchWrapper {
        width: 94%;
        border-radius: 30px;
        flex-wrap: wrap;
        position: relative;
        padding: 0;
    }
    .searchTopWrap .searchWrapper .form-control {
        width: 100%;
        border: 1px solid #ddd;
        padding: 10px 46px 10px 16px;
        border-radius: 50px;
        background: no-repeat;
    }
    .searchTopWrap .searchWrapper .form-select {
        width: 100%;
        border: 1px solid #ddd;
        margin: 20px 0;
        padding: 10px 46px;
        background-position: 10px;
    } 
    .searchTopWrap .searchWrapper .btn {
        min-width: auto;
        background: #e5e5e5 url('../../../public/search-icon.png') no-repeat center / 15px;
        color: #e5e5e5;
        position: absolute;
        right: 0;
        font-size: 0;
        width: 44px;
    }
}