.editProfile_Form, .uploadImageWrap {
    background: #fff;
    padding: 3rem 4rem;
    border-radius: 10px;
    margin-bottom: 1.375rem;
    box-shadow: 0px 3px 10px #ddd;
}

.uploadImageWrap {
    padding: 14% 0;
}

.uploadImage {
    width: 280px;
    height: 280px;
    background: #f6f6f6;
    border-radius: 50%;
    margin: auto;
    outline: 10px dashed #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.uploadImage p {
    color: #666;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
}
.uploadImageWrap .btn {
    color: #fff;
    border-radius: 30px;
    padding: 10px 30px;
    margin-top: 70px;
}
.editProfile_Form .heading {
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
}
.editProfile_Form .heading span {
    font-size: 70px;
    color: rgb(190 190 190 / 14%);
    display: block;
    margin-top: -40px;
}
.btn.submitProfile {
    color: #fff;
    padding: 10px 30px;
    border-radius: 50px;
}
.socialLinks .form-control {
    border: none;
}
.socialLinks li {
    padding-left: 10px;
    box-shadow: -1px 7px 20px #ccc;
    margin-bottom: 10px;
    border-radius: 80px !important;
}
.socialLinks li svg {
    border-radius: 50%;
    background: #eee;
    width: 40px;
    height: 40px;
    padding: 9px;
}

@media (min-width: 1025px) and (max-width: 1200px) {
    .uploadImage {
        width: 220px;
        height: 220px;
    } 
    .editProfile_Form, .uploadImageWrap {
        padding: 3rem;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .uploadImage {
        width: 175px;
        height: 175px;
    }
    .uploadImage img {
        width: 70px;
    }
    .editProfile_Form, .uploadImageWrap {
        padding: 2rem;
    }
}

@media (max-width: 767px) {
    .socialLinks li {
        padding-left: 50px;
    }
    .socialLinks li svg { 
        position: absolute;
        left: 10px;
    }
    .uploadImage {
        width: 200px;
        height: 200px;
    }
    .editProfile_Form, .uploadImageWrap {
        padding: 2rem 1rem;
    }
}